import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Dropdown from "./DropdownMenuComponents/Dropdown";

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: null,
        };
    }

    componentDidMount() {
        const imageFromSystem = jwt_decode(localStorage.getItem("autenticado_freedom")).urlLogo;
        console.log(imageFromSystem)
        if (imageFromSystem) {
            this.setState({ imageUrl: imageFromSystem });
        }
    }

    openMenu = () => {
        let menu = document.getElementById("menu");
        let body = document.getElementsByTagName("body")[0];

        menu.classList.toggle("nav__mobile");
        body.classList.toggle("scroll__block");
    };

    disable_button() {
        return jwt_decode(localStorage.getItem("autenticado_freedom")).permissao_tipo === "Read";
    }

    disable_button_master() {
        return jwt_decode(localStorage.getItem("autenticado_freedom")).permissao_tipo !== "ReadWriteMaster";
    }

    disable_button_write_and_master() {
        return jwt_decode(localStorage.getItem("autenticado_freedom")).permissao_tipo === "ReadWriteMaster" ||
            jwt_decode(localStorage.getItem("autenticado_freedom")).permissao_tipo === "ReadWrite"
            ? false
            : true;
    }

    render() {
        return (
            <>
                <nav id="menu" className="pointer">
                    <div className="nav__header" id="menuMob">
                            <div
                                className="menu__img"
                            >
                                {this.state.imageUrl ? (
                                    <img
                                        crossOrigin="true"
                                        src={`https://api.e2par.com/api/v2/realstate/uploads/${this.state.imageUrl}`}
                                        alt="Imagem do sistema"
                                        style={{
                                            objectFit: "fill", 
                                            width: 170,
                                            height: 35,
                                        }}
                                    />
                                ) : (
                                    <>
                                        <img
                                            src={require("../assets/imgs/mobilelogo.png")}
                                            alt="Logo Mobile"
                                        />
                                        <img
                                            src={require("../assets/imgs/epar_texto.png")}
                                            alt="Texto Epar"
                                        />
                                    </>
                                )}
                        </div>

                        <i className="fas fa-times pointer" id="close" onClick={this.openMenu}></i>
                        <i className="fas fa-bars pointer" id="bars" onClick={this.openMenu}></i>
                    </div>

                    <div className="nav__body">
                        <ul>
                            <Dropdown titleId={1} />
                            <Dropdown titleId={2} />
                            <Dropdown titleId={3} />
                            <Dropdown titleId={4} />
                            <Dropdown titleId={5} />
                            <Dropdown titleId={6} />
                            <Dropdown titleId={7} />
                            <Dropdown titleId={8} />
                            <Link className="nav--item" to="/">
                                <li>
                                    <i className="fas fa-sign-out-alt"></i>
                                    <p className="nav--item__p">Sair</p>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}

export default Menu;
